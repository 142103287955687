/* Portrait Picker */
#portraitPanel,
#backgroundPanel {
  background-color: #1a8c6a;
  color: white;
}

#portraitPanel,
#backgroundPanel {
  background-color: #d7d7d7;
  color: #434343;
}

#portraitPanel,
#backgroundPanel {
  padding: 20px;
  border-radius: 0 0 5px 5px;
}

#portraitPanel hr,
#backgroundPanel hr {
  border: 1px solid #434343;
}

#portraitPanel a,
#backgroundPanel a {
  color: #558df9;
}

#portraitPanel a:hover,
#backgroundPanel a:hover {
  color: #6e9dfa;
}

#backgroundPanel img,
#portraitPanel img {
  width: 150px;
  height: 150px;
  margin: 2px;
  display: block;
  cursor: pointer;
  background-color: #bbbbbb;
  box-sizing: border-box;
  border: 3px solid transparent;
  color: transparent;
}

#backgroundPanel img:hover,
#portraitPanel img:hover {
  border: 3px solid rgb(118, 89, 245);
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 474px;
}

.carousel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
