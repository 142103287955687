.layer-delete-prompt {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #4343437c;
  top: 0;
  left: -10px;
  bottom: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 99;
}

.layer-delete-prompt div {
  margin-top: 20vh;
  margin-left: auto;
  margin-right: auto;
  background-color: #eee;
  width: 25%;
  padding: 2%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .layer-delete-prompt div {
    width: 60%;
    padding: 8%;
  }
}
