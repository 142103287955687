/* Input settings */

section > div {
  margin: 0 10px;
}

label {
  display: block;
  margin: 7px 0;

  svg {
    width: 25px;
    margin-right: 5px;
  }
}

summary {
  margin: 7px 0;
  cursor: pointer;
}

.input-group {
  display: flex;
  align-items: center;
}

input {
  font-family: var.$content-font-family;
}

input ~ label {
  display: inline;
  margin-right: 1rem;
  cursor: pointer;
}

%focus-outline {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    outline: 0;
    border-color: #86b7fe;
    box-shadow: 0 0 0 1px rgb(0, 78, 88),
      0 0 0 0.25rem rgba(13, 237, 253, 0.647);
  }
}

input[type="number"],
input[type="text"],
textarea,
input[type="file"] {
  @extend %focus-outline;

  display: block;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0;
  padding: 0.375rem;

  color: #212529;
  font-size: 1rem;
  font-family: var.$content-font-family;
  line-height: 1.2;

  background-color: #fff;
  background-clip: padding-box;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  box-sizing: border-box;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  height: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

input[type="range"]:hover {
  opacity: 1;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  background: rgb(100, 100, 100);
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%;
  border: none;
  box-shadow: none;
}

input[type="range"]::-moz-range-thumb {
  width: 18px;
  height: 18px;
  background: rgb(100, 100, 100);
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%;
  border: none;
  box-shadow: none;
}

.tab input[type="range"]::-webkit-slider-thumb {
  background: #eee;
}

.tab input[type="range"]::-moz-range-thumb {
  background: #eee;
}

input[type="range"]:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px rgb(0, 78, 88), 0 0 0 0.25rem rgba(13, 237, 253, 0.647);
}

input[type="number"] {
  width: 4em;
  padding: 0.25rem;
  margin-right: 0.5rem;
}

input[type="file"]::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-inline-end-width: 1px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

::file-selector-button {
  font: inherit;
}

input[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

input[type="file"]:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

input[type="file"] {
  overflow: hidden;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

input[type="checkbox"],
input[type="radio"] {
  width: 18px;
  height: 18px;
  margin: 5px 0.25rem 0 0;
  cursor: pointer;
}

input:disabled {
  border-color: gray;
  background-color: rgb(212, 209, 209);
}

.input-group--delete {
  display: flex;
  align-items: center;
}

.input-group--delete button {
  margin: 0;
}

select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  -moz-padding-start: calc(0.75rem - 3px);

  font-family: var.$content-font-family;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.2;

  color: #212529;

  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;

  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  word-wrap: normal;
}

select.language-select {
  width: 150px;
  margin: 15px 10px 0 10px;
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg width='1em' height='1em' viewBox='0 0 16 16' class='bi bi-globe2' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855-.143.268-.276.56-.395.872.705.157 1.472.257 2.282.287V1.077zM4.249 3.539a8.372 8.372 0 0 1-1.198-.49 7.01 7.01 0 0 1 2.276-1.52 6.7 6.7 0 0 0-.597.932 8.854 8.854 0 0 0-.48 1.079zM3.509 7.5H1.017A6.964 6.964 0 0 1 2.38 3.825c.47.258.995.482 1.565.667A13.4 13.4 0 0 0 3.508 7.5zm1.4-2.741c.808.187 1.681.301 2.591.332V7.5H4.51c.035-.987.176-1.914.399-2.741zM8.5 5.09V7.5h2.99a12.342 12.342 0 0 0-.399-2.741c-.808.187-1.681.301-2.591.332zM4.51 8.5H7.5v2.409c-.91.03-1.783.145-2.591.332a12.343 12.343 0 0 1-.4-2.741zm3.99 0v2.409c.91.03 1.783.145 2.591.332.223-.827.364-1.754.4-2.741H8.5zm-3.282 3.696A12.63 12.63 0 0 1 7.5 11.91v3.014c-.67-.204-1.335-.82-1.887-1.855a7.776 7.776 0 0 1-.395-.872zm.11 2.276a6.696 6.696 0 0 1-.598-.933 8.853 8.853 0 0 1-.481-1.079 8.38 8.38 0 0 0-1.198.49 7.01 7.01 0 0 0 2.276 1.522zm-1.383-2.964a9.083 9.083 0 0 0-1.565.667A6.963 6.963 0 0 1 1.018 8.5h2.49a13.36 13.36 0 0 0 .437 3.008zm6.728 2.964a7.009 7.009 0 0 0 2.275-1.521 8.376 8.376 0 0 0-1.197-.49 8.853 8.853 0 0 1-.481 1.078 6.688 6.688 0 0 1-.597.933zM8.5 11.909c.81.03 1.577.13 2.282.287-.12.312-.252.604-.395.872-.552 1.035-1.218 1.65-1.887 1.855V11.91zm3.555-.401c.57.185 1.095.409 1.565.667A6.963 6.963 0 0 0 14.982 8.5h-2.49a13.36 13.36 0 0 1-.437 3.008zM14.982 7.5h-2.49a13.361 13.361 0 0 0-.437-3.008 9.123 9.123 0 0 0 1.565-.667A6.963 6.963 0 0 1 14.982 7.5zM11.27 2.461c.177.334.339.694.482 1.078a8.368 8.368 0 0 0 1.196-.49 7.01 7.01 0 0 0-2.275-1.52c.218.283.418.597.597.932zm-.488 1.343c-.705.157-1.473.257-2.282.287V1.077c.67.204 1.335.82 1.887 1.855.143.268.276.56.395.872z'%3E%3C/path%3E%3C/svg%3E");
}

textarea {
  resize: vertical;
}

.button {
  display: inline-block;
  margin: 0.75rem 0;
  font-size: 1em;
  padding: 0.5em;
  background-color: rgb(27, 79, 121);
  color: white;
  text-decoration: none;
  border: none;
  cursor: pointer;
  font-family: var.$content-font-family;
}

.button:hover {
  background-color: #7659f5;
}

.button.selected {
  background-color: #93b8ff;
}

.button.selected:hover {
  background-color: #a8c6ff;
}

.button.delete {
  background-color: #e94040;
}

.button.delete:hover {
  background-color: #ff2a23;
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
