/* Font face for dialogue text */
@font-face {
  font-family: "dragalialost_en";
  src: url("fonts/dragalialosten.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "dragalialost_ja";
  src: url("fonts/dragalialostjp.otf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "dragalialost_zh_tw";
  src: url("fonts/dragalialostzh_tw.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "dragalialost_zh_cn";
  src: url("fonts/dragalialostzh_cn.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
